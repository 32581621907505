import React, { useContext } from 'react';
import { SearchContext } from './search';


// eslint-disable-next-line react/display-name
export const WithSearch = (WrappedComponent: any) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const searchContext: any = useContext(SearchContext);

    return (
        <WrappedComponent
            {...props}
            {...searchContext}
        />
    );

};
