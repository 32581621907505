import { RunClass } from "../objects/runClass";
import { SortRunsByTimestamp } from "./sortRuns";

const getRuns = (props: any) => {
    const temp: any = [];
    const temp_past: any = [];
    const fromDate: Date = new Date(props.fromDate);
    props.runs.forEach((data: RunClass) => {
        const newData = data;
        const raceDate: any = data.date?.split(".");

        if (
            data.post_status === "publish" &&
            raceDate &&
            new Date(raceDate[2], raceDate[1] - 1, raceDate[0]) >= fromDate
        ) {
            temp.push(newData);
        } else if (
            data.post_status === "publish" &&
            raceDate &&
            new Date(raceDate[2], raceDate[1] - 1, raceDate[0]) < fromDate
        ) {
            temp_past.push(newData);
        }
    });

    temp.sort(function (a: any, b: any) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const btime = b.date?.split(".");
        const atime = a.date?.split(".");
        return (
            new Date(btime[2], btime[1] - 1, btime[0]).getTime() -
            new Date(atime[2], atime[1] - 1, atime[0]).getTime()
        );
    }).reverse(); //reversed the order to start at today's date and move forward

    temp_past.sort(function (a: any, b: any) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const btime = b.date?.split(".");
        const atime = a.date?.split(".");
        return (
            new Date(btime[2], btime[1] - 1, btime[0]).getTime() -
            new Date(atime[2], atime[1] - 1, atime[0]).getTime()
        );
    }); //start at today's date and move backward
    return [temp, temp_past];
};

const unPackSearchResult = async (props: any) => {
    let temp: any = [];
    props.runs.forEach((data: any) => {
        data["raceType"] = data["race_type"];
        if (data?.raceType?.includes("Repeterende") && Array.isArray(data.date)) {
            for (let i = 0; i < data.date.length; i++) {
                const newData = { ...data };
                newData["date"] = data.date[i].date;
                newData["length"] = data.length[i].length;
                newData["post_title_card"] = data.post_title_card[i].post_title_card;
                newData["images"] = newData["images"].toString()?.split(";");

                temp.push(newData);
            }
        } else {
            const newData = data;
           
            newData["images"] = newData["images"].toString()?.split(";");
            newData["avg_rating"] =
                typeof newData["avg_rating"] === "number" ? newData["avg_rating"] : 0;
            newData["num_comments"] =
                typeof newData["num_comments"] === "number" ? newData["num_comments"] : 0;
            temp.push(newData);
        }
    });

    return temp;
};

const unPackSearchResultForFavourites = async (props: any) => {
    let temp: any = [];
    props.runs.forEach((data: any) => {
        data["raceType"] = data["race_type"];
        if (data?.raceType?.includes("Repeterende") && Array.isArray(data.date)) {
            let repeating_temp: any[] = [];
            for (let i = 0; i < data.date.length; i++) {
                const newData = { ...data };
                newData["date"] = data.date[i].date;
                newData["length"] = data.length[i].length;
                newData["timestamp"] = data.timestamp[i].timestamp;
                newData["post_title_card"] = data.post_title_card[i].post_title_card;
                newData["images"] = newData["images"].toString()?.split(";");
                repeating_temp.push(newData);
            }

            // We pick the newest and closets run
            let goal = new Date().getTime() / 1000;
            repeating_temp = SortRunsByTimestamp(repeating_temp);
            // Start we setting the newest run
            let new_run = repeating_temp[0];

            // Last item that is bigger than todays date will be used as latest run
            repeating_temp.filter((run: any) => {
                if (run.timestamp > goal) {
                    new_run = run;
                }
            });

            temp.push(new_run);
        } else {
            const newData = data;

            newData["images"] = newData["images"].toString()?.split(";");
            newData["avg_rating"] =
                typeof newData["avg_rating"] === "number" ? newData["avg_rating"] : 0;
            newData["num_comments"] =
                typeof newData["num_comments"] === "number" ? newData["num_comments"] : 0;
            temp.push(newData);
        }
    });

    return temp;
};

export default getRuns;
export { unPackSearchResult, unPackSearchResultForFavourites };
