import { unPackSearchResult } from "@/helpers/getRuns";
import { RunClass } from "@/objects/runClass";
import Firebase from "../firebase";
import { useContext, useEffect, useState, createContext } from "react";
import FirebaseContext from "../context";
import { GetNumberOfRuns } from "@/HttpCalls/runs/GetNumberOfRuns";
import { FindRunsRequest, GetMapdataRequest } from "@/HttpCalls/runs/FindRunRequest";
import { SortSearchRuns } from "@/helpers/sortRuns";
import { BuildFilterOption } from "@/content/findRace/components/filter/buildFilterOptions";
import { useRouter } from "next/router";
import { useMediaQuery, useTheme } from "@mui/material";

export interface FilterListStateProps {
    searchText: string;
    searchSurface: string[];
    searchCounty: string[];
    searchCountry: string[];
    includeRunSeries: string[];
    searchDistance: string[];
    whichListValue?: string[];
    race_type?: string[];
    showCity?: string;
    showCountry?: string;
    heilighted: string;
    tickets: boolean;
}

export const SearchContext: any = createContext(null);

export const defaultState = {
    searchText: "",
    country: ["Alle"],
    county: ["Alle"],
    length: [0, 100],
    terrain_type: ["Alle"],
    race_type: ["Alle"],
    fromDate: new Date(),
    toDate: null,
    showCity: "",
    showCountry: "",
    heilighted: "",
    tickets: false,
};

// Need this to avoid type error
declare global {
    interface Window {
        google_optimize: any;
    }
}

export const SortValues = {
    default: "date",
    relevance: "relevance",
    date: "date",
};

export const Search = (props: any) => {
    const [displayList, setDisplayList] = useState<RunClass[]>([]);
    const [sortValue, setSortValue] = useState(SortValues.default);
    const [doneLoading, setDoneLoading] = useState<boolean>(false);
    const [lastItem, setLastItem] = useState<boolean>(false);
    const [toggleReset, setToggleReset] = useState<boolean>(false);
    const [startTutorial, setStartTutorial] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<any | null>(null);
    const [estimatedHits, setEstimatedHits] = useState<number>(0);
    const [oldHits, setOldHits] = useState<number>(0);
    const [view, setView] = useState("image");
    const [savedFilterOptions, setSavedFilterOptions] = useState({
        query: "",
        filterOptions: BuildFilterOption(defaultState),
        offset: 0,
    });

    const [stateArray, setStateArray] = useState<any>(defaultState);
    const [totalNumber, setTotalNumber] = useState<number>(0);
    const firebaseContext: Firebase | any = useContext(FirebaseContext);

    // TODO: Need to fix params check for country and region
    const [showCountry, setShowCountry] = useState("");
    const [showCity, setShowCity] = useState("");

    const theme = useTheme();
    const isSmallSize = useMediaQuery(theme.breakpoints.down("md"));

    const router = useRouter();

    // Section with useeffects

    // Get total numbers of run at init
    useEffect(() => {
        const GetAllNumbers = async () => {
            const result = await GetNumberOfRuns();
            if (result.status === 200) {
                setTotalNumber(result.data);
                setEstimatedHits(result.data);
            }
        };
        if(router.asPath.includes("finnlop")){
            GetAllNumbers();
        }
    }, []);

    const getResult = async (
        query: string,
        filterOptions: string,
        localSortValue?: string,
        viewOption?: string,
        limit?: number,
        local_offset?: number
    ) => {
        const match_view = viewOption ?? view;

        switch (match_view) {
            case "map":
                return await GetMapdataRequest(
                    query,
                    filterOptions ?? savedFilterOptions.filterOptions,
                    local_offset ?? 0,
                    localSortValue ?? sortValue,
                    10000
                );
            default:
                return await FindRunsRequest(
                    query,
                    filterOptions ?? savedFilterOptions.filterOptions,
                    local_offset ?? 0,
                    localSortValue ?? sortValue,
                    limit ? limit : isSmallSize ? 10 : 20
                );
        }
    };

    const resetLastItem = () => {
        setSavedFilterOptions({
            query: savedFilterOptions.query,
            filterOptions: savedFilterOptions.filterOptions,
            offset: isSmallSize ? 10 : 20,
        });
        setLastItem(false);
    };

    const searchWithSaveOptions = (
        filterOptions: any,
        localSortValue: string,
        viewOption: string
    ) => {
        filterOptions["searchText"] = savedFilterOptions.query;
        filterOptions["toDate"] = null;
        if (filterOptions["fromDate"] != null) {
            filterOptions["fromDate"] = new Date();
        }
        setStateArray(filterOptions);
        const filterString = BuildFilterOption(filterOptions);
        UpdateRuns(savedFilterOptions.query, filterString, localSortValue, viewOption);
    };

    // Method that fetches search preferrances from session or user object
    // Could be optimized by combining view and sort with search state
    const checkForDefaultSearch = (current_user: any) => {
        // TODO: Could be compromised
        if (Object.keys(router.query).length === 0) {
            let filterOptions: any;
            let viewOption: any;
            let sortOption: any;

            // Check session storage if user is not logged in
            if (current_user && Object.keys(current_user).length === 0) {
                filterOptions = sessionStorage.getItem("filterOptions");
                filterOptions = JSON.parse(filterOptions);
                viewOption = sessionStorage.getItem("viewOption");
                viewOption = JSON.parse(viewOption);
                sortOption = sessionStorage.getItem("sortOption");
                sortOption = JSON.parse(sortOption);
            }
            // Check user props if user is logged in
            else if (current_user) {
                filterOptions = current_user["defaultFilterOptions"] ?? defaultState;
                viewOption =
                    current_user["defaultView"] !== "" && current_user["defaultView"]
                        ? current_user["defaultView"]
                        : "image";
                sortOption =
                    current_user["defaultSortOption"] !== "" && current_user["defaultSortOption"]
                        ? current_user["defaultSortOption"]
                        : "date";
            }

            // Filter only if we found anything
            if (filterOptions && viewOption) {
                setSortValue(sortOption);
                setView(viewOption);
                searchWithSaveOptions(filterOptions, sortOption, viewOption);
            }
        }
    };

    // Saveing search preferrances to session for non auth users and to the user document for auth users
    const saveSearchPreferences = () => {
        if (firebaseContext.auth.currentUser === null) {
            sessionStorage.setItem("filterOptions", JSON.stringify(stateArray));
            sessionStorage.setItem("viewOption", JSON.stringify(view));
            sessionStorage.setItem("sortOption", JSON.stringify(sortValue));
            firebaseContext.logFirebaseEvent({}, "logged_in", true, "saved_search");
        } else {
            const searchDefault = {
                defaultView: view,
                defaultFilterOptions: stateArray,
                defaultSortOption: sortValue,
            };
            firebaseContext.updateUser(searchDefault);
            sessionStorage.removeItem("filterOptions");
            sessionStorage.removeItem("viewOption");
            firebaseContext.logFirebaseEvent({}, "not_logged_in", true, "saved_search");
        }
    };

    const UpdateViewAndOption = (sortOption: string, viewOption: string) => {
        setSortValue(sortOption);
        setView(viewOption);
    };

    // Changes the view and reset list of runs for map
    const UpdateView = (new_view: string) => {
        setDisplayList([]);
        setTimeout(() => {
            UpdateRuns(
                savedFilterOptions.query,
                savedFilterOptions.filterOptions,
                sortValue,
                new_view
            );
            setView(new_view);
        }, 100);
    };

    // Fetches runs based on query, sort, view and filterprops
    // List and images uses same endpoint while map is using a seperate one for fetching all runs and less data
    const UpdateRuns = async (
        query: string,
        filterOptions: string,
        localSortValue?: string,
        viewOption?: string,
        local_offset?: number
    ) => {
        const result = await getResult(
            query,
            filterOptions,
            localSortValue,
            viewOption,
            20,
            local_offset
        );
        if (filterOptions.split("timestamp").length - 1 === 1) {
            let new_options = filterOptions;
            new_options = new_options.replaceAll("timestamp >=", "timestamp <=");
            const old_result = await getResult(query, new_options, localSortValue, viewOption, 1);
            if (old_result.status === 200) {
                setOldHits(old_result.data["estimatedTotalHits"]);
            } else {
                setOldHits(0);
            }
        } else {
            setOldHits(0);
        }
        let offset = 20;
        if (result.status === 200 && Object.keys(result.data).includes("hits")) {
            let runs = await unPackSearchResult({ runs: result.data["hits"] });
            if (sortValue === SortValues.date) {
                runs = SortSearchRuns([...runs]);
            }
            offset = result.data["offset"];
            setDisplayList(runs);
            setEstimatedHits(result.data["estimatedTotalHits"]);
            setLastItem(result.data["last_item"]);
        } else if (result.status === 200) {
            setDisplayList(result.data);
            setEstimatedHits(result.data["estimatedTotalHits"]);
            setLastItem(result.data["last_item"]);
        }

        setSavedFilterOptions({
            query: query,
            filterOptions: filterOptions ?? savedFilterOptions.filterOptions,
            offset: offset,
        });
    };

    // Using stored offset to fetch next 20 runs
    const loadMoreRuns = async (fromDate = null, toDate = null, offset?: any) => {
        const local_offset = offset ? (offset - 1) * 20 : savedFilterOptions.offset;
        const result = await FindRunsRequest(
            savedFilterOptions.query,
            savedFilterOptions.filterOptions,
            local_offset,
            sortValue
        );
        if (result.status === 200) {
            const runs = await unPackSearchResult({
                runs: result.data["hits"],
                date: fromDate,
                toDate: toDate,
            });

            setLastItem(result.data["last_item"]);
            setSavedFilterOptions({
                query: savedFilterOptions.query,
                filterOptions: savedFilterOptions.filterOptions,
                offset: result.data["offset"],
            });
            return runs;
        }
    };

    // TODO: What does this do?
    const UpdateSavedState = (value: any) => {
        setSavedState(value);
    };

    // Toggle sort option and reset last item value
    const ChangeSortValue = (newValue: string) => {
        setSortValue(newValue);
        UpdateRuns(savedFilterOptions.query, savedFilterOptions.filterOptions, newValue);
        setLastItem(false);
    };

    const removeTimestampGreaterThanEqualTo = (inputString: string) => {
        // Define the regular expression pattern to match "timestamp >= number"
        const regex = /timestamp\s+>=\s+\d+\s*/;

        // Replace the matched pattern with an empty string
        const newString = inputString.replace(regex, "");

        // Return the modified string
        return newString;
    };

    const getAllResults = () => {
        let new_filteroptions = removeTimestampGreaterThanEqualTo(savedFilterOptions.filterOptions);
        if (new_filteroptions === " ") {
            new_filteroptions = "";
        } else if (new_filteroptions.startsWith(" AND ")) {
            new_filteroptions = new_filteroptions.replace(" AND ", "");
        }
        if (new_filteroptions.endsWith(" AND ")) {
            const lastIndex = new_filteroptions.lastIndexOf("AND");

            // If "AND" is found in the string
            if (lastIndex !== -1) {
                // Split the string at the last "AND" and join it back without "AND"
                new_filteroptions =
                    new_filteroptions.slice(0, lastIndex) + new_filteroptions.slice(lastIndex + 3);
            }
        }
        UpdateGlobalState("fromDate", null);
        UpdateRuns(savedFilterOptions.query, new_filteroptions);
        setLastItem(false);
    };

    const seeFromToday = () => {
        const stateCopy: any = { ...stateArray };
        let newFromDate = new Date();
        stateCopy["fromDate"] = newFromDate;
        let new_filteroptions = BuildFilterOption(stateCopy);
        UpdateGlobalState("fromDate", newFromDate);
        UpdateRuns(savedFilterOptions.query, new_filteroptions);
        setLastItem(false);
    };

    const UpdateCityAndCountry = (city: string, country: string) => {
        defaultState["showCity"] = city;
        defaultState["showCountry"] = country;
        setShowCity(city);
        setShowCountry(country);
        // TODO: Need to fetch races from country
        setToggleReset(!toggleReset);
    };

    // General method for updating filter props
    const UpdateGlobalState = (key: string, value: any) => {
        // const stateCopy: any = { ...stateArray };
        // stateCopy[key] = value;
        // setStateArray(stateCopy);
        setStateArray((prevState: any) => {
            if (prevState[key] === value) return prevState; // Avoid unnecessary re-renders
            return { ...prevState, [key]: value };
        });
        // setStateArray((prevState:any) => {
        //     const stateCopy = { ...prevState };
        //     stateCopy[key] = value;
        //     return stateCopy;
        // });
    };

    // Reset search to default state
    const ResetState = (stateCopy: any) => {
        setStateArray(stateCopy);
    };

    const startTutorialNow = (value: boolean) => {
        setStartTutorial(value);
    };

    const sampleContext = {
        firebase: { ...firebaseContext },
        startTutorialNow,
        UpdateCityAndCountry,
        UpdateRuns,
        loadMoreRuns,
        UpdateGlobalState,
        ResetState,
        UpdateSavedState,
        ChangeSortValue,
        UpdateView,
        saveSearchPreferences,
        checkForDefaultSearch,
        resetLastItem,
        UpdateViewAndOption,
        getAllResults,
        seeFromToday,
        view,
        sortValue,
        stateArray,
        doneLoading,
        displayList,
        totalNumber,
        toggleReset,
        showCountry,
        savedState,
        estimatedHits,
        oldHits,
        lastItem,
        startTutorial,
    };
    return <SearchContext.Provider value={sampleContext}>{props.children}</SearchContext.Provider>;
};
