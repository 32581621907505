import React, { useState, useEffect, FC } from "react";
import { TextField, Autocomplete, Paper, Typography, ListItem, Box } from "@mui/material";
import { GetRunTitles, GetSearchSuggestions } from "@/HttpCalls/runs/GetRunTitles";
import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

interface SearchWithSuggestionsProps {
    onChange: (e: any) => void;
    checkKey: (e: any) => void;
    GoToFindRunWithSearch: (search_string: string) => void;
    firebase: any;
    onClose?: () => void;
    mobile?: boolean;
}

const CustomPaper = (props: any) => {
    const { t } = useTranslation("translation");
    return (
        <>
            <Paper {...props}>
                {props.children.some((item: any) => item !== null) && (
                    <ListItem>
                        <Typography sx={{ textDecoration: "underline", fontWeight: 800 }}>
                            {t("components.navigation.events")}
                        </Typography>
                    </ListItem>
                )}
                {props.children}
            </Paper>
        </>
    );
};

const SearchWithSuggestions: FC<SearchWithSuggestionsProps> = ({
    onChange,
    checkKey,
    GoToFindRunWithSearch,
    firebase,
    onClose,
    mobile,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [fixedSuggestions, setFixedSuggestions] = useState<any[]>([]);
    const [suggestions, setSuggestions] = useState<any[]>([]);

    const handleURLChange = () => {
        if (window && window.location.search) {
            const searchState: any = new URLSearchParams(window.location.search);
            const searchValue = searchState.get("searchText");
            if (searchValue || searchValue === "") {
                setInputValue(searchValue);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("popstate", handleURLChange);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { t } = useTranslation("translation");
    const router = useRouter();

    useEffect(() => {
        const getSuggestions = async () => {
            // const titles_data = await GetRunTitles();
            const titles_data = await GetSearchSuggestions(inputValue);
            if (titles_data.status == 200) {
                const filter_data = titles_data.data.map((title: any) => {
                    return {
                        label: title["post_title"],
                        url: title["post_url"],
                    };
                });
                const seen = new Set();
                const unique_titles = filter_data.filter((item: any) => {
                    const duplicate = seen.has(item.label);
                    seen.add(item.label);
                    return !duplicate;
                });
                setFixedSuggestions(Array.from(unique_titles));
            }
        };
        if (inputValue != "") {
            const timer = setTimeout(() => {
                getSuggestions();
            }, 200);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        if (fixedSuggestions.length > 0) {
            // Filter or fetch based on input value
            const filteredSuggestions: any[] = fixedSuggestions.filter((suggestion) =>
                suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]); // Hide suggestions if input is empty
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixedSuggestions]);

    const handleClose = (event: any, reason: any) => {
        if (reason === "createOption" || reason === "blur") {
            firebase.logFirebaseEvent(null, "autocomplete_normal_search", true, "search");
            checkKey(event.key);
        }
    };

    const updateSearchText = (event: any, newInputValue: any) => {
        onChange(newInputValue);
        setInputValue(newInputValue);
        if (event?.key == "Enter") {
            const found_event = fixedSuggestions.find((item: any) => item.label === newInputValue);

            if (found_event && found_event.url !== "") {
                firebase.logFirebaseEvent(null, "autocomplete_move_directly", true, "search");
                const new_url = "/run/" + found_event.url;
                if (onClose) {
                    onClose();
                }
                router.push(new_url);
            } else {
                GoToFindRunWithSearch(newInputValue);
            }
        }
    };

    return (
        <Autocomplete
            freeSolo
            options={suggestions}
            getOptionLabel={(option) => option?.label ?? option}
            renderOption={(props, option) => (
                <Box
                    onClick={(e: any) => {
                        if (onClose) {
                            onClose();
                        }
                    }}
                >
                    <ListItem {...props} key={option.label}>
                        <Link href={"/run/" + option.url}>{option.label}</Link>
                    </ListItem>
                </Box>
            )}
            inputValue={inputValue}
            onClose={handleClose}
            onInputChange={(event, newInputValue) => updateSearchText(event, newInputValue)}
            sx={{
                width: "100%",
            }}
            PaperComponent={CustomPaper}
            renderInput={(params: any) => {
                params["InputProps"]["disableUnderline"] = true;
                params["InputProps"]["style"] = { fontSize: "20px" };
                // params["InputProps"]["endAdornment"] = (
                //     <InputAdornment position="end" sx={{display: {xs:"flex", lg:"none"}}}>
                //         <Search width={20} />
                //     </InputAdornment>
                // );
                return (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder={t("components.navigation.placeholder")}
                    />
                );
            }}
        />
    );
};

export default SearchWithSuggestions;
