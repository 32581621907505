import { useState, useEffect } from "react";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { ReactComponent as Calendar } from "@/assets/calendar.svg";
import { raceTypesOptions } from "./constants";
import { surfaceOptions } from "@/constants/surfaceConstants";
import { countryOptions } from "@/constants/countryConstants";

import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SvgIcon from "@mui/material/SvgIcon";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SelectComponent from "./selectComponent";
import OptionComponent from "./optionComponent";
import { WithSearch } from "@/components/Firebase/Search/searchContext";
import { GetAllCountries } from "@/HttpCalls/runs/GetAllCountriesRequest";
import { Grid, Box, Typography, Stack, Button } from "@mui/material";
import { BuildFilterOption } from "./buildFilterOptions";
import SaveSearchModal from "./SaveSearchModal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { defaultState } from "@/components/Firebase/Search/search";
import FilterBox from "./filterbox";
import useTranslation from "next-translate/useTranslation";
import { ReactComponent as ClearIcon } from "@/assets/singleExit.svg";
import { ReactComponent as Ticket } from "@/assets/ticket.svg";
import { ReactComponent as Star } from "@/assets/starBroken.svg";
import { returnCorrectLanguage } from "@/helpers/returnCorrectLanguage";
import SearchWithSuggestions from "@/components/Navigation/Search/SearchSuggestions";
import { useSharedState } from "@/helpers/metricContext";
import { getCorrectMetric } from "@/helpers/metricConverter";
import { useRouter } from "next/router";

const classes: any = {
    datepicker: {
        width: "50%",
        height: "40px",
        borderRadius: "40px",
        border: "1px solid rgba(0, 0, 0, 0.3)",
    },

    datepickerText: {
        pl: 2,
        pt: 0.5,
        pr: 2,
    },
    accordian: {
        boxShadow: "none",
    },
    accordianSummary: {
        backgroundColor: "#fcfcfc",
        padding: "0px 0px 1px 0px",
    },
    searchField: {
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "40px",
        width: "100%",
        justifyContent: "center",
        padding: "5px",
        px: 2,
        display: { lg: "none", xs: "block" },
    },
    resize: {
        fontSize: "20px",
    },
    subtitles: {
        fontWeight: "600",
    },
};

export interface IDefaultFilterStateProps {
    searchText: string;
    country: string[];
    county: string[];
    length: number[];
    terrain_type: string[];
    race_type: string[];
    fromDate: null | Date;
    toDate: null | Date;
    heilighted: string;
    tickets: boolean;
}

export const defaultStateWithoutDate = {
    searchText: "",
    country: ["Alle"],
    county: ["Alle"],
    length: [0, 100],
    terrain_type: ["Alle"],
    race_type: ["Alle"],
    fromDate: null,
    toDate: null,
    heilighted: "",
    tickets: false,
};

export const updateQuery = (queryName: string, value: any, pathname: string) => {
    // Get the query string from the URL
    const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);

    // Convert the URLSearchParams to a dictionary
    const newQuery: any = Object.fromEntries(params.entries());
    if (value || queryName === "searchText") {
        newQuery[queryName] = value;
    } else {
        delete newQuery[queryName];
    }

    const newUrl = pathname + "?" + new URLSearchParams(newQuery).toString();
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
    window.dispatchEvent(new PopStateEvent("popstate"));
};

const DesktopFilter = (props: any) => {
    const [stateArray, setStateArray] = useState<any>(JSON.parse(JSON.stringify(defaultState)));
    const [showReset, setShowReset] = useState(false);
    const [openSaveSearch, setOpenSaveSearch] = useState(false);
    const [countries, setCountries] = useState<any | null>(null);
    const [marks, setMarks] = useState([
        {
            value: 0,
            label: "0 km",
        },
        {
            value: 42.2,
            label: "42.2 km",
        },
        {
            value: 100,
            label: "100 +",
        },
    ]);
    const { sharedValue } = useSharedState();
    const { t, lang } = useTranslation("translation");
    const router = useRouter();

    const translationSurface: any = t("findRace.search.surface", {}, { returnObjects: true });
    const translationCounty: any = t("findRace.search.county", {}, { returnObjects: true });
    const translationRaceTypes: any = t("findRace.search.raceTypes", {}, { returnObjects: true });

    const surfaces: any = {
        ...translationSurface,
        ...surfaceOptions,
    };

    let county: any = {
        ...countryOptions,
        ...translationCounty,
    };

    const raceTypes: any = {
        ...translationRaceTypes,
        ...raceTypesOptions,
    };

    useEffect(() => {
        if (sharedValue === "miles") {
            setMarks([
                {
                    value: 0,
                    label: "0 Mi",
                },
                {
                    value: 42.2,
                    label: "26.2 Mi",
                },
                {
                    value: 100,
                    label: "62 +",
                },
            ]);
        } else if (sharedValue === "metric") {
            setMarks([
                {
                    value: 0,
                    label: "0 km",
                },
                {
                    value: 42.2,
                    label: "42.2 km",
                },
                {
                    value: 100,
                    label: "100 +",
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedValue]);

    const getCountries = async () => {
        try {
            const localCopy = JSON.parse(JSON.stringify(county));
            const response = await GetAllCountries();
            if (response.status === 200) {
                const countriesToKeep = [...response.data];
                const tempTextOptions: any[] = [];
                if (localCopy) {
                    //Push correct translation strings of each country in response.data to tempTextoptions
                    localCopy.options.forEach((e: any) => {
                        if (countriesToKeep.includes(e.optionId) || e.optionId === "Alle") {
                            tempTextOptions.push(county.textOptions[e.optionId] || e.optionId);
                        }
                    });
                    localCopy.textOptions = [...tempTextOptions];

                    //Keep the corresponding country values as they are stored in the database
                    localCopy.options = localCopy.options.filter(
                        (e: any) => countriesToKeep.includes(e.optionId) || e.optionId === "Alle"
                    );

                    //Sort translation strings but keep the first item: "Alle" on top
                    const alle = localCopy.textOptions.splice(0, 1);
                    localCopy.textOptions.sort((a: any, b: any) => (a > b ? 1 : -1));
                    localCopy.textOptions.splice(0, 0, alle[0]);

                    //Sort corresponding country values to correspond to sorted translation strings
                    localCopy.options.sort((a: any, b: any) =>
                        localCopy.textOptions.indexOf(county.textOptions[a.optionId]) >
                        localCopy.textOptions.indexOf(county.textOptions[b.optionId])
                            ? 1
                            : -1
                    );
                }
            }
            setCountries(localCopy);
        } catch {}
    };

    useEffect(() => {
        if(!countries && props.openSearch){
            getCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.openSearch]);

    useEffect(() => {
        if (props.stateArray) {
            setStateArray(props.stateArray);
        }
        if (JSON.stringify(defaultState) !== JSON.stringify(props.stateArray)) {
            if (JSON.stringify(defaultStateWithoutDate) !== JSON.stringify(props.stateArray)) {
                setShowReset(true);
            }
        }
    }, [props.stateArray]);

    const resetFilters = async () => {
        const localeState = JSON.parse(JSON.stringify(defaultStateWithoutDate));
        setStateArray(localeState);
        setShowReset(false);
        props.ResetState(localeState);
        await props.UpdateRuns("", "");
    };

    const UpdatePopularSearch = (input?: string) => {
        const stateCopy: any = { ...stateArray };
        stateCopy["heilighted"] = stateCopy["heilighted"] === "" ? "Highlighted" : "";
        if (input) {
            stateCopy["heilighted"] = input;
        }
        updateQuery("heiglighted", stateCopy["heilighted"], router.asPath);

        const filterOptions = BuildFilterOption(stateCopy, null, null, null);
        const query = stateArray.searchText;
        props.UpdateGlobalState("heilighted", stateCopy["heilighted"]);
        props.UpdateRuns(query, filterOptions);
        setStateArray(stateCopy);
    };

    const UpdateTicketsSearch = (input?: boolean) => {
        const stateCopy: any = { ...stateArray };
        stateCopy["tickets"] = input;
        const filterOptions = BuildFilterOption(stateCopy, null, null, null);
        const query = stateArray.searchText;
        props.UpdateGlobalState("tickets", stateCopy["tickets"]);
        props.UpdateRuns(query, filterOptions);
        setStateArray(stateCopy);
        updateQuery("tickets", input, router.asPath);
    };

    const UpdateOptionState = (key: string, values: string[]) => {
        const stateCopy: any = { ...stateArray };
        if (["Alle"].some((v) => values.includes(v)) && values[0] !== "Alle" && key === "country") {
            UpdateOptionWithBoth(["Alle"], ["Alle"], false);
        } else {
            const indexOfValues = values.indexOf("Alle");
            if (indexOfValues !== -1 && values[0] !== "Alle") {
                values = [values[indexOfValues]];
            } else if (indexOfValues !== -1) {
                values.splice(indexOfValues, 1);
            }
            stateCopy[key] = values;
            updateQuery(key, values, router.asPath);
            props.UpdateGlobalState(key, values);
            setStateArray(stateCopy);
            if (!showReset) {
                setShowReset(true);
            }
            const filterOptions = BuildFilterOption(stateCopy, null, null, null);
            const query = stateArray.searchText;

            props.UpdateRuns(query, filterOptions);
        }
    };

    const UpdateOptionWithBoth = (countries: string[], regions: string[], fromSubMenu: boolean) => {
        const stateCopy: any = { ...defaultStateWithoutDate };
        stateCopy["country"] = countries;
        stateCopy["county"] = regions;
        stateCopy["fromDate"] = fromSubMenu ? null : stateCopy.fromDate;
        stateCopy["toDate"] = fromSubMenu ? null : stateCopy.toDate;

        props.ResetState(stateCopy);
        setStateArray(stateCopy);
        if (!showReset) {
            setShowReset(true);
        }
        const filterOptions = BuildFilterOption(stateCopy, null, null, null);
        const query = stateArray.searchText;
        props.UpdateRuns(query, filterOptions);
    };

    const UpdateArrayState = (key: string, value: string) => {
        const stateCopy: any = { ...stateArray };

        let values = stateCopy[key];
        if (["Alle"].some((v) => values.includes(v)) && value !== "Alle") {
            const indexOfValues = values.indexOf("Alle");
            values.splice(indexOfValues, 1);
        }

        if (values.includes(value)) {
            const indexOfValues = values.indexOf(value);
            values.splice(indexOfValues, 1);
        } else if (!["Alle"].some((v) => values.includes(v)) && ["Alle"].includes(value)) {
            values = [value];
        } else {
            values.push(value);
        }

        stateCopy[key] = values;
        updateQuery(key, values, router.asPath);
        props.UpdateGlobalState(key, values);
        props.ResetState(stateCopy);
        setStateArray(stateCopy);
        if (!showReset) {
            setShowReset(true);
        }
        const filterOptions = BuildFilterOption(stateCopy, null, null, null);
        const query = stateArray.searchText;

        props.UpdateRuns(query, filterOptions);
    };

    const handleChange = (event: Event | null, inputLength: number | number[]) => {
        props.UpdateGlobalState("length", inputLength);
        const stateCopy: any = event === null ? { ...defaultStateWithoutDate } : { ...stateArray };
        stateCopy["length"] = inputLength;
        setStateArray(stateCopy);
        if (event === null) {
            props.ResetState(stateCopy);
        }
        if (!showReset) {
            setShowReset(true);
        }
        const filterOptions = BuildFilterOption(stateCopy, null, null, inputLength);
        const query = stateArray.searchText;

        props.UpdateRuns(query, filterOptions);
    };

    const handleLengthChange = (inputLength: number | number[]) => {
        const stateCopy: any = { ...stateArray };
        stateCopy["length"] = inputLength;
        setStateArray(stateCopy);
        updateQuery("length", inputLength, router.asPath);
    };

    const updateSearchState = (event: any) => {
        const stateCopy: any = { ...stateArray };
        stateCopy["searchText"] = event ?? "";
        setStateArray(stateCopy);
        props.UpdateGlobalState("searchText", event ?? "");
    };

    const handleSearchChange = (event: any) => {
        const stateCopy: any = { ...stateArray };
        // stateCopy["searchText"] = event ?? "";
        setStateArray(stateCopy);
        if (!showReset) {
            setShowReset(true);
        }
        const filterOptions = BuildFilterOption(stateArray, null, null, null);
        const query = stateArray.searchText;

        props.UpdateRuns(query, filterOptions);
    };

    const handleDateChange = (event: any, stateName: string) => {
        const newDateValue = event !== null ? new Date(event) : null;
        const stateCopy: any = { ...stateArray };
        let newFromDate = null;
        let newToDate = null;
        if (stateName === "fromDate") {
            newFromDate = newDateValue;
        }
        if (stateName === "toDate") {
            newToDate = newDateValue;
        }
        stateCopy[stateName] = newDateValue;
        props.UpdateGlobalState(stateName, newDateValue);

        setStateArray(stateCopy);
        if (!showReset) {
            setShowReset(true);
        }
        const filterOptions = BuildFilterOption(stateCopy, newFromDate, newToDate, null);
        const query = stateArray.searchText;

        props.UpdateRuns(query, filterOptions);
        updateQuery(stateName, newDateValue?.toLocaleDateString(), router.asPath);
    };

    const SaveSearch = () => {
        props.saveSearchPreferences();
        setOpenSaveSearch(true);
    };

    const setDistanceLabel = (x: number) => {
        if (sharedValue === "miles") {
            return getCorrectMetric(x);
        } else {
            return x;
        }
    };

    return (
        <>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                {/* <Grid
                    item
                    sx={{
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "space-between",
                        pb: 1,
                    }}
                    xs={12}
                >
                    <Box id={props.mobile ? "mobile-save" : "my-fifth-step"} ml={-1}>
                        <Button id="saveFilterButton" color="primary" onClick={SaveSearch}>
                            <Bookmark fill="#ff8640" width={18} />
                            <Typography variant="body1" ml={1}>
                                {" "}
                                {t("findRace.search.savedSearch.savedSearch")}
                            </Typography>
                        </Button>
                    </Box>
                </Grid> */}

                <Grid
                    item
                    sx={{ display: { xs: "none", md: "none", lg: "flex" }, alignItems: "center" }}
                    xs={12}
                >
                    {showReset && (
                        <Button
                            id="resetFilterButton"
                            variant="contained"
                            sx={{ display: { xs: "none", lg: "flex" } }}
                            color="primary"
                            onClick={() => resetFilters()}
                        >
                            <Typography sx={{ fontSize: "12px", mr: 1 }}>
                                {t("findRace.search.removeFilters")}
                            </Typography>
                            <ClearIcon stroke="#fff" width={12} />
                        </Button>
                    )}
                </Grid>
                <Grid
                    item
                    sx={{ display: { xs: "none", md: "none", lg: "flex" }, alignItems: "center" }}
                    xs={12}
                >
                    <FilterBox />
                </Grid>
                <Grid item sx={{ width: "100%", overflow: "hidden" }}>
                    <Box sx={classes.searchField} id="searchMobile">
                        <SearchWithSuggestions
                            onChange={(e) => {
                                updateSearchState(e);
                            }}
                            GoToFindRunWithSearch={(search_string: string) => {
                                props.onClose();
                                handleSearchChange(search_string);
                            }}
                            checkKey={(e: any) => {
                                handleSearchChange(e);
                            }}
                            onClose={() => props.onClose()}
                            mobile={true}
                            firebase={props.firebase}
                        />
                    </Box>
                    <Box sx={{ py: 1 }}>
                        <Typography variant="body1" sx={classes.subtitles}>
                            {t("findRace.search.date")}
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ py: 1 }}
                        >
                            <Box sx={classes.datepicker}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={returnCorrectLanguage(lang)}
                                >
                                    <MobileDatePicker
                                        value={
                                            stateArray.fromDate
                                                ? new Date(stateArray.fromDate)
                                                : null
                                        }
                                        onChange={(e: any) => handleDateChange(e, "fromDate")}
                                        closeOnSelect={true}
                                        slotProps={{
                                            textField: {
                                                variant: "standard",
                                                color: "primary",
                                                placeholder: t("findRace.search.fromDate"),
                                                sx: classes.datepickerText,
                                                InputProps: {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Calendar fill="black" width={15} />
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true,
                                                },
                                            },
                                            actionBar: {
                                                actions: [],
                                            },
                                            toolbar: {
                                                hidden: true,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={classes.datepicker}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={returnCorrectLanguage(lang)}
                                >
                                    <MobileDatePicker
                                        value={
                                            stateArray.toDate ? new Date(stateArray.toDate) : null
                                        }
                                        onChange={(e: any) => handleDateChange(e, "toDate")}
                                        closeOnSelect={true}
                                        slotProps={{
                                            textField: {
                                                variant: "standard",
                                                color: "primary",
                                                placeholder: t("findRace.search.toDate"),
                                                sx: classes.datepickerText,
                                                InputProps: {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Calendar fill="black" width={15} />
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true,
                                                },
                                            },
                                            actionBar: {
                                                actions: [],
                                            },
                                            toolbar: {
                                                hidden: true,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Stack>
                    </Box>

                    <Box sx={{ py: 1 }}>
                        <FormControl>
                            <Typography variant="body1" sx={classes.subtitles}>
                                {t("components.navigation.eventTopics.1")}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                                <SvgIcon sx={{ mt: 1 }}>
                                    <Star width={20} />
                                </SvgIcon>
                                <Checkbox
                                    checked={stateArray.heilighted === "Highlighted" ? true : false}
                                    onChange={() => UpdatePopularSearch()}
                                    value="Highlighted"
                                />
                            </Box>
                        </FormControl>
                        <FormControl sx={{ ml: 2 }}>
                            <Typography variant="body1" sx={classes.subtitles}>
                                {t("tickets")}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                                <SvgIcon sx={{ mt: 1 }}>
                                    <Ticket width={22} />
                                </SvgIcon>
                                <Checkbox
                                    checked={stateArray.tickets}
                                    onChange={(e: any) => UpdateTicketsSearch(e.target.checked)}
                                />
                            </Box>
                        </FormControl>
                    </Box>

                    <Accordion disableGutters sx={classes.accordian} defaultExpanded>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            expandIcon={<KeyboardArrowDownRoundedIcon />}
                            id="panel1a-header"
                            sx={classes.accordianSummary}
                        >
                            <Typography variant="body1" sx={classes.subtitles}></Typography>
                            <Box component="div" sx={{ py: 1, width: "100%" }}>
                                <Typography variant="body1" sx={classes.subtitles}>
                                    {t("findRace.search.countryTitle")}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={classes.accordianSummary}>
                            {countries !== null && (
                                <Box sx={{ mt: 0, mb: 2 }}>
                                    <SelectComponent
                                        value={stateArray.country}
                                        UpdateOptionState={(e: any) =>
                                            UpdateOptionState("country", e.target.value)
                                        }
                                        options={countries?.options}
                                        textOptions={countries?.textOptions}
                                    />

                                    <Select
                                        labelId={props.description}
                                        id={props.description}
                                        multiple={true}
                                        renderValue={(selected: any) => {
                                            if (selected[0] === "Alle") {
                                                return county.textOptions["Alle"];
                                            } else {
                                                return selected.join(", ");
                                            }
                                        }}
                                        value={stateArray.county}
                                        onChange={(e: any) =>
                                            UpdateOptionState("county", e.target.value)
                                        }
                                        sx={{
                                            backgroundColor: "#fcfcfc",
                                            marginLeft: "auto",
                                            width: "48%",
                                            float: "right",
                                            borderRadius: "40px",
                                            height: "40px",
                                            fieldset: { legend: { width: "unset" } },
                                        }}
                                        size="small"
                                    >
                                        <MenuItem value={"Alle"}>
                                            <Checkbox
                                                checked={stateArray.county.includes("Alle")}
                                            />
                                            {county.textOptions["Alle"]}
                                        </MenuItem>
                                        {county.options.map((element: any) => {
                                            return (
                                                stateArray.country.includes(element?.optionId) &&
                                                element.subOptions.map(
                                                    (region: any, index: number) => {
                                                        return (
                                                            <MenuItem
                                                                key={region + index}
                                                                value={region}
                                                            >
                                                                <Checkbox
                                                                    checked={stateArray.county.includes(
                                                                        region
                                                                    )}
                                                                />{" "}
                                                                {region}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )
                                            );
                                        })}
                                    </Select>
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion disableGutters sx={classes.accordian} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownRoundedIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={classes.accordianSummary}
                        >
                            <Box component="div" sx={{ py: 1, width: "100%" }}>
                                <Typography variant="body1" sx={classes.subtitles}>
                                    {t("findRace.search.distance.filterTitle")}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={classes.accordianSummary}>
                            <Box sx={{ mt: 0, mb: 2, pl: 4, pr: 4 }}>
                                <Slider
                                    getAriaLabel={() => "Distance range"}
                                    max={100}
                                    step={1}
                                    marks={marks}
                                    value={stateArray.length}
                                    valueLabelFormat={(x: number) => setDistanceLabel(x)}
                                    onChange={(e: any, newValue: any) => {
                                        handleLengthChange(newValue);
                                    }}
                                    onChangeCommitted={(e: any, newValue: number | number[]) =>
                                        handleChange(e, newValue)
                                    }
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion disableGutters sx={classes.accordian}>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownRoundedIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={classes.accordianSummary}
                        >
                            <Box component="div" sx={{ py: 1, width: "100%" }}>
                                <Typography variant="body1" sx={classes.subtitles}>
                                    {t("findRace.search.surface.filterTitle")}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={classes.accordianSummary}>
                            <Box sx={{ mt: 0, mb: 2 }}>
                                <OptionComponent
                                    value={stateArray.terrain_type}
                                    UpdateOptionState={(e: any) =>
                                        UpdateArrayState("terrain_type", e)
                                    }
                                    options={surfaces?.optionValues}
                                    textOptions={surfaces.options}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion disableGutters sx={classes.accordian}>
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownRoundedIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={classes.accordianSummary}
                        >
                            <Box component="div" sx={{ py: 1, width: "100%" }}>
                                <Typography sx={classes.subtitles} variant="body1">
                                    {t("findRace.search.raceTypes.filterTitle")}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={classes.accordianSummary}>
                            <Box sx={{ mt: 0, mb: 2 }}>
                                <OptionComponent
                                    value={stateArray.race_type}
                                    UpdateOptionState={(e: any) => UpdateArrayState("race_type", e)}
                                    options={raceTypes?.optionValues}
                                    textOptions={raceTypes.options}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* <Grid
                    item
                    sx={{
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 3,
                    }}
                    xs={12}
                >
                    <>
                        {props.firebase.currentUser.defaultFilterOptions && (
                            <Button
                                id="resetFilterButton"
                                sx={{ display: { xs: "none", lg: "block" } }}
                                color="primary"
                                onClick={() =>
                                    props.checkForDefaultSearch(props.firebase.currentUser)
                                }
                            >
                                <Box sx={{ display: "flex" }}>
                                    <Bookmark fill="#ff8640" width={18} />
                                    <Typography variant="body1" ml={1}>
                                        {t("findRace.search.useSavedSearch")}
                                    </Typography>
                                </Box>
                            </Button>
                        )}
                    </>
                </Grid> */}
            </Grid>

            <SaveSearchModal
                open={openSaveSearch}
                onClose={() => setOpenSaveSearch(false)}
                loggedIn={
                    props.firebase?.auth?.currentUser
                        ? Object.keys(props.firebase?.auth?.currentUser).length > 0
                        : false
                }
            />
        </>
    );
};

function valuetext(value: number) {
    return `${getCorrectMetric(value)}`;
}

export default WithSearch(DesktopFilter);
