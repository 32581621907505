import { FC, useState } from "react";
import { Typography, Button, Box, Modal, Dialog, DialogContent, DialogTitle } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import SignInPage, { RegisterComponent } from "@/content/login/login";

interface SaveSearchModalProps {
    open: boolean;
    loggedIn: boolean;
    onClose: VoidFunction;
}

const SaveSearchModal: FC<SaveSearchModalProps> = ({ open, loggedIn, onClose }) => {
    const [openLogin, setOpenLogin] = useState(false);
    const [openCreateUser, setOpenCreateUser] = useState(false);
    const { t } = useTranslation("translation");

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle></DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {loggedIn && (
                        <>
                            <Typography variant="h5" component="p">
                                {t("findRace.search.savedSearch.loggedIn")}
                            </Typography>
                        </>
                    )}
                    {!loggedIn && !openCreateUser && !openLogin && (
                        <>
                            <Typography variant="h5" component="p">
                                {t("findRace.search.savedSearch.createUser")}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Button
                                    variant="contained"
                                    sx={{ m: 2 }}
                                    onClick={() => setOpenCreateUser(true)}
                                >
                                    {t("findRace.search.savedSearch.ctaText")}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ m: 2 }}
                                    onClick={() => setOpenLogin(true)}
                                >
                                    {t("components.navigation.logIn")}
                                </Button>
                            </Box>
                        </>
                    )}
                    {openCreateUser && (
                        <RegisterComponent onClose={() => setOpenCreateUser(false)} />
                    )}
                    {openLogin && (
                        <Modal open={openLogin} onClose={() => setOpenLogin(false)}>
                            <Box>
                                <SignInPage
                                    onClose={() => setOpenLogin(false)}
                                    createNewUser={false}
                                />
                            </Box>
                        </Modal>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SaveSearchModal;
