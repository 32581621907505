import { Box, Stack, Typography } from "@mui/material";
import { FC, ReactNode, useEffect } from "react";



/***** TYPES *****/
interface SuccessMessageBoxProps {
    text: string;
    subText?: string;
    onClose?: VoidFunction;
    children?: ReactNode;
    timeout?: number;
}

/***** COMPONENT-FUNCTION *****/
const MessageBox: FC<SuccessMessageBoxProps> = ({
    onClose,
    children,
    text,
    timeout,
    subText,
}): JSX.Element => {

    

    useEffect(() => {
        if (onClose) {
            setTimeout(
                () => {
                    onClose();
                },
                timeout ? timeout : 2000
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    /*** Return statement ***/
    return (
        <Stack
            sx={{ maxWidth: "400px", maxHeight: "500px", minHeight: "250px", padding: "1em" }}
            spacing={3}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
        >
            <Box sx={{ textAlign: "left" }}>
                <Typography display="block" variant="h5" component="p">
                    {text}
                </Typography>
                {subText && (
                    <Typography
                        display="block"
                        variant="h6"
                        component="p"
                        sx={{ pt: 2, textAlign: "center" }}
                    >
                        {subText}
                    </Typography>
                )}
            </Box>
            {children}
        </Stack>
    );
};

export default MessageBox;
