import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function GetRunTitles(): Promise<any> {
    const URL = firebaseTriggerUrls.getRunTitles;

    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 10000,

    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

async function GetSearchSuggestions(query:string): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_search_suggestions"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_search_suggestions";

    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 10000,
        data: {
            searchQuery: query
        }

    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

export { GetRunTitles, GetSearchSuggestions };