import { RunClass } from "../objects/runClass";

interface SortRunProps {
  initialList: any[];
  initialList_past: any[];
  searchText: string;
  searchSurface: string[];
  searchCounty: string[];
  searchCountry: string[];
  includeRunSeries: string[];
  searchDistance: string[];
  raceTypes?: string[];
  showCity?: string;
  showCountry?: string;
}


const filterDistance = (inputDistance: number, searchDistances: string[]) => {
  let minDistance: number;
  let maxDistance: number;
  for (const index in searchDistances) {
    switch (searchDistances[index]) {
      case "Alle":
        minDistance = 0;
        maxDistance = 1000000;
        break;

      case "0-5 KM":
        minDistance = 0;
        maxDistance = 5.4;
        break;
      case "6-10 KM":
        minDistance = 5.5;
        maxDistance = 10.4;
        break;
      case "11-21 KM":
        minDistance = 10.5;
        maxDistance = 21.1;
        break;
      case "22-42 KM":
        minDistance = 21.2;
        maxDistance = 42.2;
        break;
      case "42-> KM":
        minDistance = 42.3;
        maxDistance = 1000000;
        break;
      default:
        minDistance = 0;
        maxDistance = 1000000;
    }
    if (
      inputDistance >= minDistance &&
      inputDistance <= maxDistance
    ) {
      return true;
    }
  }
};

const searchArray = (searchArray: string[], searchWord: string) => {
  let returnValue = -1;
  searchWord = searchWord.toLowerCase();
  for (const index in searchArray) {
    if (searchWord.search(searchArray[index].toLowerCase()) !== -1) {
      returnValue = 1;
    }
  }
  return returnValue;
};

export function SortRuns(props: SortRunProps) {
  let updatedList = props.initialList;
  let updatedList_past = props.initialList_past;

  const showCity = props.showCity?.toLowerCase();
  const showCountry = props.showCountry?.toLowerCase();
  const searchWord = props.searchText?.toLowerCase();
  let searchType = props.raceTypes?.includes("Alle") ? [] : props.raceTypes;
  if (searchType === undefined) {
    searchType = [];
  }
  const matchTerreng: string[] = props.searchSurface.includes("Alle")
    ? []
    : props.searchSurface;
  const matchFylke: string[] = props.searchCounty.includes("Alle")
    ? []
    : props.searchCounty;
  let matchCountry: string[] = props.searchCountry.includes("Alle")
    ? []
    : props.searchCountry;
  if (showCountry) {
    matchCountry = [showCountry.charAt(0).toUpperCase() + showCountry.slice(1)];
  }



  updatedList = updatedList.filter(function (item: RunClass) {
    return (
      item.post_status === "publish" &&
      (item.post_title?.toLowerCase().search(searchWord) !== -1 ||
        searchWord === ("" || " ") ||
        item.place?.toLowerCase().search(searchWord) !== -1 ||
        item.search_words?.toLowerCase().search(searchWord) !== -1) &&
      (!showCity || item.post_title?.toLowerCase().search(showCity) !== -1 ||
        showCity === ("" || " ") ||
        item.place?.toLowerCase().search(showCity) !== -1 ||
        item.search_words?.toLowerCase().search(showCity) !== -1) &&
      (matchTerreng.some((e) => item.terrain_type.includes(e)) ||
        matchTerreng.length === 0) &&
      (searchArray(matchFylke, item.county) !== -1 || matchFylke.length === 0) &&
      (searchArray(matchCountry, item.country) !== -1 ||
        matchCountry.length === 0) &&
      (item.length.find((e: any) => filterDistance(e, props.searchDistance)) ||
        item.length.length === 0 ||
        item.length.length === 1 && filterDistance(item.length[0], props.searchDistance)) &&
      ((searchType && searchType.some((e) => item.raceType.includes(e))) ||
        searchType?.length === 0)
    );
  });

  updatedList_past = updatedList_past.filter(function (item: RunClass) {
    return (
      item.post_status === "publish" &&
      (item.post_title?.toLowerCase().search(searchWord) !== -1 ||
        searchWord === ("" || " ") ||
        item.place?.toLowerCase().search(searchWord) !== -1 ||
        item.search_words?.toLowerCase().search(searchWord) !== -1) &&
      (!showCity || item.post_title?.toLowerCase().search(showCity) !== -1 ||
        showCity === ("" || " ") ||
        item.place?.toLowerCase().search(showCity) !== -1 ||
        item.search_words?.toLowerCase().search(showCity) !== -1) &&
      (matchTerreng.some((e) => item.terrain_type.includes(e)) ||
        matchTerreng.length === 0) &&
      (searchArray(matchFylke, item.county) !== -1 || matchFylke.length === 0) &&
      (searchArray(matchCountry, item.country) !== -1 ||
        matchCountry.length === 0) &&
      (item.length.find((e: any) => filterDistance(e, props.searchDistance)) ||
        item.length.length === 0 ||
        item.length.length === 1 && filterDistance(item.length[0], props.searchDistance)) &&
      ((searchType && searchType.some((e) => item.raceType.includes(e))) ||
        searchType?.length === 0)
    );
  });

  return [updatedList, updatedList_past];
}



export const SortSearchRuns = (runs: any[]) => {
  runs.sort(function (a: any, b: any) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    const btime = b.date.split(".");
    const atime = a.date.split(".");
    return (
      new Date(btime[2], btime[1] - 1, btime[0]).getTime() -
      new Date(atime[2], atime[1] - 1, atime[0]).getTime()
    );
  }).reverse();
  return runs;
}

export const SortRunsByTimestamp = (runs: any[]) => {
  runs.sort(function (a: any, b: any) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      a.timestamp - b.timestamp
    );
  }).reverse();
  return runs;
}