import { Box, Button } from "@mui/material"
import { useState, useEffect } from "react";
import { withSearch } from "@/components/Firebase/Search";
import { ReactComponent as ClearIcon } from '@/assets/singleExit.svg'
import { BuildFilterOption } from "./buildFilterOptions";
import { surfaceOptions } from "@/constants/surfaceConstants";
import { countryOptions } from "@/constants/countryConstants";
import { raceTypesOptions } from "./constants";
import useTranslation from "next-translate/useTranslation";
import { updateQuery } from "./desktopFilter";
import { useRouter } from "next/router";

const classes = {
    filterBoxes: {
        backgroundColor: "#fcfcfc",
        boxShadow: '2px 2px 5px 0px #cdcdcd',
        px: 2,
        m: 0.5,
        fontSize: '12px'
    }

}

interface FilterBoxProps {
    text: string;
    value: any;
    statename: string;
}

const FilterBox = (props: any) => {
    const [currentFilters, setCurrentFilters] = useState<FilterBoxProps[]>([]);
    const { t, lang } = useTranslation("translation");
    const router = useRouter();

    const translationSurface: any = t("findRace.search.surface", {}, { returnObjects: true });
    const translationCounty: any = t("findRace.search.county", {},{ returnObjects: true });
    const translationRaceTypes: any = t("findRace.search.raceTypes",{}, { returnObjects: true });

    const surfaces: any = {
        ...translationSurface,
        ...surfaceOptions
    };

    const county: any = {
        ...countryOptions,
        ...translationCounty
    };

    const raceTypes: any = {
        ...translationRaceTypes,
        ...raceTypesOptions
    };

    const removeFilter = (item: FilterBoxProps) => {
        const stateCopy = { ...props.stateArray };
        if (item.statename === "searchText") {
            stateCopy[item.statename] = "";
            updateQuery(item.statename, "", router.asPath);
        }

        if (
            Array.isArray(stateCopy[item.statename])) {
            const index = stateCopy[item.statename].findIndex((stateValue: any) => stateValue === item.value);
            if (index !== -1) {
                stateCopy[item.statename].splice(index, 1);
                if (stateCopy[item.statename].length === 0) {
                    stateCopy[item.statename].push("Alle");
                    updateQuery(item.statename, null, router.asPath);
                }else{
                    updateQuery(item.statename, stateCopy[item.statename], router.asPath);
                }
            }
        }
        if (item.statename === "fromDate" || item.statename === "toDate") {
            stateCopy[item.statename] = null;
            updateQuery(item.statename, null, router.asPath);
        }
        
        props.UpdateGlobalState(item.statename, stateCopy[item.statename]);
        const filterOptions = BuildFilterOption(stateCopy, null, null, null)
        props.UpdateRuns(stateCopy["searchText"], filterOptions);
        updateFilterBox(stateCopy);
    }

    const updateFilterBox = (stateCopy:any) =>{
        const keys = Object.keys(stateCopy);
        const currentFilterProps: FilterBoxProps[] = [];
        for (const key of keys) {
            if (key === "searchText" && stateCopy[key] !== "") {
                currentFilterProps.push({ text: stateCopy[key], value: stateCopy[key], statename: key });
            }
            if (
                Array.isArray(stateCopy[key]) && stateCopy[key].length > 0 &&
                stateCopy[key][0] !== "Alle" &&
                key !== "length") {
                for (const item of stateCopy[key]) {
                    const index = key === "terrain_type" ? surfaces.optionValues.findIndex((e: any) => e === item) :
                        key === "race_type" ? raceTypes.optionValues.findIndex((e: any) => e === item) :
                            key === "country" ? county.options.findIndex((e: any) => e.optionId === item) : -1;
                    let text = item;
                    if (index !== -1) {
                        text = key === "terrain_type" ? surfaces.options[index] :
                            key === "race_type" ? raceTypes.options[index] :
                                key === "country" ? (county.textOptions[item] || item) : "";
                    }
                    currentFilterProps.push({ text: text, value: item, statename: key });
                }
            }

            if ((key === "fromDate" || key === "toDate") && stateCopy[key] !== null) {
                currentFilterProps.push({ text: new Date(stateCopy[key])?.toLocaleDateString(lang), value: stateCopy[key], statename: key });
            }
        }
        setCurrentFilters(currentFilterProps);
    }

    useEffect(() => {
        if (props.stateArray) {
            updateFilterBox(props.stateArray);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stateArray]);

    return (
        <Box>
            {
                currentFilters.map((item: FilterBoxProps, index: number) => <FilterBouble key={index} props={item} removeFilter={() => removeFilter(item)} />)
            }
        </Box>
    )
}

const FilterBouble = (props: { props: FilterBoxProps, removeFilter: any }) => {


    return (
        <Button sx={classes.filterBoxes} endIcon={<ClearIcon stroke="#343434" width={12} />} onClick={() => props.removeFilter()}>
            {props.props.text}
        </Button>
    );
}

export default withSearch(FilterBox);
