import { IDefaultFilterStateProps } from "./desktopFilter";

export const BuildFilterOption = (stateCopy: IDefaultFilterStateProps, newFromDate: any = null, newToDate: any = null, inputLength: any = null) => {
    let filter = "";
    const keys = Object.keys(stateCopy);

    for (const key of keys) {
        if (
            key !== "searchText"
            && key !== "fromDate"
            && key !== "toDate"
            && key !== "length"
            && key !== "heilighted"
        ) {
            let valuesWithQuotes = stateCopy[key as keyof IDefaultFilterStateProps];
            valuesWithQuotes = Array.isArray(valuesWithQuotes) ? valuesWithQuotes?.map((item: any) => `'${item}'`) : [];
            if (!valuesWithQuotes?.includes("'Alle'") && valuesWithQuotes.length > 0) {
                filter += `${filter != "" ? " AND" : ""} ${key} IN [${valuesWithQuotes?.join(', ')}]`;
            }
        }
    }

    if (stateCopy.fromDate != null || newFromDate != null) {
        const date = new Date(newFromDate ?? stateCopy.fromDate);
        date.setHours(0, 0, 0, 0);
        filter += `${filter != "" ? " AND " : " "}timestamp >= ${Math.floor(date.getTime() / 1000)}`;
    }
    if (stateCopy.toDate != null || newToDate != null) {
        const date = new Date(newToDate ?? stateCopy.toDate);
        date.setHours(23, 0, 0, 0);
        filter += `${filter != "" ? " AND " : " "}timestamp <= ${Math.floor(date.getTime() / 1000)}`;
    }

    const filterLength = inputLength ?? stateCopy.length;
    if (filterLength[0] > 0) {
        filter += `${filter != "" ? " AND " : " "}length >= ${filterLength[0]}`;
    }
    if (filterLength[1] < 100) {
        filter += `${filter != "" ? " AND " : " "}length <= ${filterLength[1]}`;
    }

    if (stateCopy.heilighted === "Highlighted") {
        filter += `${filter != "" ? " AND " : " "}partner='ja'`;
    }
    if (stateCopy.tickets) {
        filter += `${filter != "" ? " AND " : " "}event_ids EXISTS`;
    }
    return filter;
}