import { FilterProps } from "./filterPropsInterface";

export const raceTypesOptions: FilterProps = {
  filterId: "raceTypesId",
  filterTitle: "",
  stateName: "raceTypes",
  optionValues: [
    "Alle",
    "Backyard Ultra",
    "Barneløp",
    "Cannonball",
    "Etappeløp",
    "Gateløp",
    "Hinderløp/OCR",
    "Hundeløp",
    "Karusell",
    "Motbakke",
    "Repeterende",
    "Stafett",
    "Terrengløp",
    "Timeløp",
    "Trappeløp",
    "Triatlon",
    "Trugeløp",
    "Virtuelt",
    "Stiløp",
    "Baneløp"
  ]
};
