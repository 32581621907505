import {Checkbox, Select, MenuItem} from '@mui/material';

const SelectComponent = (props: any) => {

    const renderCorrectText = (selected: string[]) => {
        const returnArray = [];
        for (const item of selected) {
            let index = -1;
            props.options.forEach((element: any, option_index: number) => {
                if (element === item || element?.optionId === item) {
                    index = option_index;
                    return;
                }
            });
            if (index !== -1) {
                returnArray.push(props.textOptions[index]);
            }
        }
        return returnArray.join(", ");
    }
    return (
        <Select
            labelId={props.description}
            id={props.description}
            multiple={true}
            renderValue={(selected) => renderCorrectText(selected)}
            value={props.value}
            onChange={(e: any) => props.UpdateOptionState(e)}
            sx={{ backgroundColor: "#fcfcfc", marginLeft: "auto", width: "48%", height: '40px',  borderRadius: '40px', fieldset: { legend: { width: "unset" } } }} size="small"
        >
            {props.options.map((element: any, index: number) => {
                return <MenuItem key={element?.optionId ?? element} value={element?.optionId ?? element}>{(element !== "Alle" || element?.optionId !== "Alle") && <Checkbox checked={props.value?.includes(element?.optionId ?? element)} />}{props.textOptions[index]}</MenuItem>
            })}
        </Select>
    );
}

export default SelectComponent;
