import { enUS, es, sv, da, nb } from 'date-fns/locale';

export const returnCorrectLanguage = (lang: string) => {
  switch (lang) {
    case "es":
      return es;
    case "sv":
      return sv;
    case "da":
      return da;
    case "no":
      return nb;
    case "en":
      return enUS;
    default:
      return enUS;
  }
}
