import {Checkbox, FormControlLabel, Box} from '@mui/material';

const OptionComponent = (props: any) => {
    return (
        <Box sx={{ overflowX: "hidden"}}>
            {props.options.map((element: any, index: number) => {
                return (
                    <FormControlLabel
                        key={index}
                        checked={props.value?.includes(element)}
                        control={
                            <Checkbox name="checkedC" color="primary"
                                onChange={() => props.UpdateOptionState(element)}
                            />
                        }
                        label={props.textOptions ? props.textOptions[index] : ""}
                        sx={{ width: "100%" }}
                    />
                );
            })}
        </Box>
    );
}

export default OptionComponent;
